import { useSelector } from "react-redux";
import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import "./style.css";
import Table from "../../components/table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Loader } from "rsuite";

export default function Invoice() {
  const language = useSelector((state) => state.language);
  const userData = localStorage.getItem("logAuth")
    ? JSON.parse(localStorage.getItem("logAuth"))
    : null;

  const [fatoora, setFatoora] = useState([]);
  const [combineFatoorah, setCombineFatoorah] = useState([]);
  const [fatooraAgent, setFatooraAgent] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleGetAllData = async () => {
    setLoader(true);
    let allData = [];
    let currentPage = 1;
    const perPage = 15; // Based on the API's per_page value

    try {
      while (true) {
        const url = `https://app.fawaterk.com/api/v2/getInvoicesData?start_date=${startDate}&end_date=${endDate}&page=${currentPage}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization:
              "Bearer e6a44e951fab927a3dd8928a5574fad22e4e70afece3e875ab",
          },
        });

        const data = await response.json();

        if (data?.data && data?.data?.length) {
          // Append the current page's data to allData
          allData = [...allData, ...data.data];
        }

        // Check if we are on the last page
        if (currentPage >= data.pagination.last_page) {
          break;
        }

        // Move to the next page
        currentPage++;
      }

      // After fetching all pages, set the accumulated data to the state
    } catch (error) {
      console.error(error);
      toast.error(
        language === "ar"
          ? "حدث خطأ ما"
          : "There was an issue retrieving the data"
      );
    }
    setFatoora(allData);
    let combined = [];
    const findElement = (invoiceId) => {
      return fatooraAgent?.filter(
        (fa_item) => fa_item?.invoice_id == invoiceId
      );
    };
    if (allData?.length && fatooraAgent?.length) {
      allData?.map((item) => {
        const invoiceId = item?.id;
        if (findElement(invoiceId)?.length) {
          combined.push({ ...item, ...findElement(invoiceId)[0] });
        }
      });
      console.log(combined);
      setCombineFatoorah(combined);
      setFilteredData(combined)
    }
    setLoader(false);
  };

  const headers = [
    {
      label: "invoiceId",
      dataIndex: "id",
    },
    {
      label: language === "ar" ? "اسم المنتج" : "Product Name",
      dataIndex: "product_name",
    },
    {
      label: language === "ar" ? "اسم المستخدم" : "User Name",
      dataIndex: "user_name",
    },
    {
      label: language === "ar" ? "رقم الفاتورة" : "Invoice Number",
      dataIndex: "fatoorah_id",
    },
    {
      label: language === "ar" ? "تاريخ الانشاء" : "Created At",
      dataIndex: "agent_email",
      type: "children",
      children: ({ row }) => <p>{row?.created_at}</p>,
    },
    {
      label: language === "ar" ? "رقم الشخص" : "Phone Number",
      dataIndex: "user_phone",
    },
    {
      label: language === "ar" ? "الحالة" : "Status",
      dataIndex: "status_text",
    },
    {
      label: language === "ar" ? "المبلغ" : "Amount",
      dataIndex: "price",
      type: "children",
      children: ({ headers, row }) => {
        return <span>{parseFloat(row?.price)}</span>;
      },
    },
    {
      label: language === "ar" ? "المبلغ الكلي" : "Total Amount",
      dataIndex: "submission_price",
      type: "children",
      children: ({ headers, row }) => {
        return <span>{parseFloat(row?.submission_price)}</span>;
      },
    },
    {
      label: language === "ar" ? "مصاريف إدارية" : "Administrative Expenses",
      dataIndex: "administrative_expenses",
      type: "children",
      children: ({ headers, row }) => {
        return (
          <span>
            {(+row?.price * (+row?.administrative_expenses / 100)).toFixed(1)}
          </span>
        );
      },
    },
  ];
  const [loader, setLoader] = useState(false);
  
  const handleGetLocalInvoices = () => {
    axios
      .post(
        `https://baseeta-form.com/basita/getAgentFatoorah.php?agent_id=${userData?.agent_id}`
      )
      .then((res) => {
        if (res.data) {
          setFatooraAgent(res.data);
        } else {
          toast.error(language === "ar" ? "حدث خطأ ما" : "There's a problem");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(language === "ar" ? "حدث خطأ ما" : "There's a problem");
      });
  };

  useEffect(() => {
    if (startDate && endDate) {
      handleGetAllData();
      handleGetLocalInvoices();
    }
  }, [startDate, endDate]);

  // useEffect(() => {
  //   let combined = [];
  //   if (fatoora?.length && fatooraAgent?.length) {
  //     fatoora?.map((item) => {
  //       const invoiceId = item?.id;
  //       fatooraAgent?.map((fa_item) => {
  //         if (fa_item?.invoice_id == invoiceId) {
  //           combined = [...fa_item, ...item];
  //         }
  //       });
  //     });
  //     setCombineFatoorah(combined);
  //   }
  // }, [fatoora, fatooraAgent]);
  const [fatoorahStatus, setFatooraStatus] = useState();
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    if (fatoorahStatus) {
      setFilteredData(
        combineFatoorah?.filter(
          (item) =>
            item?.status_text == fatoorahStatus
        )
      );
    } else {
      setFilteredData(combineFatoorah);
    }
  }, [fatoorahStatus, combineFatoorah]);
  return (
    <div className="h-container con-h">
      <DefaultLayout>
        <div className="childs">
          <div className="header">
            <ContentNav
              head={language === "ar" ? "فواتيرك" : "Your Invoices"}
            />
          </div>

          <div className="filters">
            <label>{language === "ar" ? "تاريخ البدء" : "Start Date"}</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />

            <label>{language === "ar" ? "تاريخ الانتهاء" : "End Date"}</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />

           <div className="inputField">
              <label htmlFor="doctorId">
                {language === "ar" ? "حالة الدفع" : "Invoice Status"}
              </label>
              <select
                name="doctorId"
                id="doctorId"
                onChange={(e) => setFatooraStatus(e.target.value || null)}
              >
                <option value="">{language === "ar" ? "الكل" : "All"}</option>
                <option value={"paid"}>{"Paid"}</option>
                <option value={"unpaid"}>{"UnPaid"}</option>
              </select>
            </div>
          </div>

          {loader ? (
            <Loader size="lg" />
          ) : (
            <Table headers={headers} body={filteredData} />
          )}
        </div>
      </DefaultLayout>
    </div>
  );
}
