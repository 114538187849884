import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import Message_History from "../pages/Message_History";
import Login from "../pages/login";
import Sections from "../pages/Sections";
import WebsiteDatas from "../pages/Website_Data";
import Services from "../pages/Services";
import HowWork from "../pages/HowWork";
import UserData from "../pages/UserData";
import Invoice from "../pages/Invoice/Invoice";

function RouteComponent() {
  return (
    <Routes>
        <Route path="/invoice"  element={<Invoice />}/>
        <Route path="/" element={<Message_History />}>
        <Route path="/Message_History" element={<Message_History />} />
        <Route path="*" element={<Message_History />} />
        <Route path="website_data" element={<WebsiteDatas />} />
        {/* <Route path="Login" element={<Login />} /> */}
        <Route path="sections" element={<Sections />} />
        <Route path="services" element={<Services />} />
        <Route path="how_work" element={<HowWork />} />
        <Route path="user_data" element={<UserData />} />
      </Route>
    </Routes>
  );
}

export default RouteComponent;
