import React, { Fragment, useEffect, useState } from "react";
import { SideNavData } from "../sidenav/sidenavdata";
import { NavLink, Navigate } from "react-router-dom";
import "./style.css";
import { Icon } from "@iconify/react";
import axios from "axios";
import { useSelector } from "react-redux";
function SideNavbar({ active, setActive }) {
  const userData = localStorage?.getItem("logAuth")
    ? JSON.parse(localStorage?.getItem("logAuth"))
    : null;
  const language = useSelector((state) => state.language);
  return (
    <Fragment>
      <aside className={active ? "active" : ""}>
        <div className="list-close" onClick={() => setActive(false)}>
          <img
            src="https://res.cloudinary.com/duovxefh6/image/upload/v1693603266/close_bphmao.png"
            alt=""
            width={"24px"}
            style={{ marginLeft: "20px" }}
          />
        </div>
        <div className="logo-ic">
          <img src={require("../../assets/logo/Logo-3_rndc5c.png")} alt="" />
        </div>
        <div className="links">
          {SideNavData(userData?.show_fawaterek == "1").map((item, index) => {
            return (
              item?.path ? <NavLink
                to={item?.path}
                onClick={() => setActive(false)}
                key={index}
              >
                <img src={item?.icon} alt="" />
                <em>{language == "ar" ? item?.label : item?.label_en}</em>
              </NavLink> : null
            )
          })}
        </div>
      </aside>
    </Fragment>
  );
}

export default SideNavbar;
