// firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAqycvPR9uotnc1TBuSbT_EsI63DMRojnI",
  authDomain: "baseeta-b5c83.firebaseapp.com",
  databaseURL: "https://baseeta-b5c83-default-rtdb.firebaseio.com",
  projectId: "baseeta-b5c83",
  storageBucket: "baseeta-b5c83.firebasestorage.app",
  messagingSenderId: "462218294608",
  appId: "1:462218294608:web:6efe69bea7dc1d368fe855",
  measurementId: "G-Z9VPR1JDKJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

export { app, analytics, database };
